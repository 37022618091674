import React from 'react'
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

const Banner = () => {
    return (
        <Element name='pageTop' className='banner'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-xl-6'>
                        <h1 className='mb-4'>FyberTech Yazılım ve İnternet Hizmetleri</h1>
                        <p className='mb-0'>
                            FyberTech Yazılım ve İnternet Hizmetleri olarak, her kurumun gereksinimlerinin farklı olduğunun farkında olarak size özgü çözümler üreterek, satış sonrası Çağrı Merkezi desteğimizle her zaman yanınızdayız.
                        </p>
                        <Link to="aboutUs" spy={true} smooth={true} duration={0}>
                            <button className='btn btn-outline-light btn-explore mt-5 py-3 px-5'>
                                Detaylı Bilgi
                            </button>
                        </Link>
                    </div>
                    <div className='col-xl-6 banner-image'>
                        <img src='./images/bg.png' width="100%" />
                    </div>
                </div>
            </div>
        </Element>
    )
}

export default Banner;