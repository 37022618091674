import React, { useEffect, useState } from 'react'
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

const Contact = () => {
    const [form, setForm] = useState({
        fullname: "",
        email: "",
        phone: "",
        message: ""
    });
    const [active, setActive] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm((prevProps) => ({
            ...prevProps,
            [name]: value
        }));
    };

    useEffect(() => {
        const { fullname, email, phone, message } = form;
        if (fullname != "" && email != "" && phone != "" && message != "") {
            setActive(true);
        }
        else {
            setActive(false);
        }
    })

    return (
        <Element name='contact' className='contact'>
            <div className='container'>
                <h3 className='text-center mb-3 subtitle'>Bizimle İletişime Geçin</h3>
                <p className='mb-0 text-center mb-5'>Hizmetlerimiz Hakkında Bilgi almak için Formu Doldurmanız Yeterlidir</p>
                <div className='row align-items-center'>
                    <div className='col-xl-6 contact-image'>
                        <img src='./images/contact-image.png' width="100%" />
                    </div>
                    <div className='col-xl-6'>
                        <div className='contact-card shadow'>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" placeholder="İsim Soyisim" name='fullname' value={form.fullname} onChange={handleInputChange} />
                                <label htmlFor="">İsim Soyisim</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="email" className="form-control" placeholder="E-posta" name='email' value={form.email} onChange={handleInputChange} />
                                <label htmlFor="">E-posta</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="tel" className="form-control" id="phone" placeholder="Telefon" name='phone' value={form.phone} onChange={handleInputChange} />
                                <label htmlFor="">Telefon</label>
                            </div>
                            <div className="form-floating">
                                <textarea className="form-control" placeholder="Mesajınız" style={{ height: '135px' }} name='message' value={form.message} onChange={handleInputChange} />
                                <label htmlFor="">Mesaj</label>
                            </div>
                            <button className={`btn btn-submit mt-5 py-3 px-5 ${active ? "" : "disabled"}`}>Gönder</button>
                        </div>
                    </div>

                </div>
            </div>
        </Element>
    )
}

export default Contact