/* eslint-disable */
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from './components/Header'
import Banner from './components/Banner'
import AboutUs from './components/AboutUs'
import Services from './components/Services'
import WhyUs from './components/WhyUs'
import Contact from './components/Contact'
import Footer from './components/Footer'
import ScrollTop from './components/ScrollTop'

function App() {
    return (
        <>
            <Header />
            <Banner />
            <AboutUs />
            <Services />
            <WhyUs />
            <Contact />
            <Footer />
            <ScrollTop />
        </>
    );
}

export default App;
