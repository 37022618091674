import React from 'react'

const Footer = () => {
    return (
        <div className='footer'>
            <div className='container'>
                <div className='d-flex align-items-center justify-content-between footer-container'>
                    <img src='./images/logo/png/fyber-logo.png' width="100px" />
                    <p className='mb-0 mt-xl-0 mt-lg-0 mt-md-0 mt-4'>© 2023 - FyberTech Yazılım ve İnternet Hizmetleri Limited Şirketi. Tüm Hakları Saklıdır.</p>
                </div>
            </div>
        </div>
    )
}

export default Footer;