import React from 'react'
import Slider from "react-slick";
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

const Services = () => {
    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: false,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false
                }
            }
        ]
    };
    return (
        <Element name='services' className='services'>
            <div className='container'>
                <h3 className='text-center mb-4 subtitle'>Hizmetlerimiz</h3>
                <Slider {...settings}>
                <div className='card shadow border-0'>
                        <img src="./images/helpdesk.jpg" width="75%" className='rounded' />
                        <div className='card-body p-4'>
                            <h5>Çağrı Merkezi</h5>
                            <p className='mb-0 text-truncate-3'>
                                Alanında uzman ekip arkadaşlarımızla müşterilerimizin memnuniyetini en öncelikli görev kabul ederek Çağrı Merkezi'mizle yanınızdayız.
                            </p>
                        </div>
                    </div>
                <div className='card shadow border-0'>
                        <img src="./images/santral.jpg" width="75%" className='rounded' />
                        <div className='card-body p-4'>
                            <h5>Santral</h5>
                            <p className='mb-0 text-truncate-3'>
                                Cep telefonundan, PC’den veya ofisteki IP telefonundan aynı hatta bağlanarak erişim sağlayabileceğiniz Santral konusunda çözümler üretiyoruz.
                            </p>
                        </div>
                    </div>
                    <div className='card shadow border-0'>
                        <div className='d-flex align-items-center'>
                            <img src="./images/crm.jpg" width="75%" className='rounded' />
                        </div>
                        <div className='card-body p-4'>
                            <h5>CRM</h5>
                            <p className='mb-0 text-truncate-3'>
                                Mevcut ve potansiyel müşterilerinizle olan tüm etkileşimlerinize yardımcı olacak Müşteri İlişkileri Yönetimi (CRM) konusunda sizlere destek oluyoruz.
                            </p>
                        </div>
                    </div>
                    
                    <div className='card shadow border-0'>
                        <img src="./images/security.jpg" width="75%" className='rounded' />
                        <div className='card-body p-4'>
                            <h5>Siber Güvenlik</h5>
                            <p className='mb-0 text-truncate-3'>
                                FyberTech Yazılım olarak, gerek Fortinet cihazları arasında gerek farklı marka firewall cihazlarından Fortigate ürünlerine konfigürasyon işlemlerinde hızlı, güvenli, müşteri memnuniyetini esas alan taşıma hizmeti sunmaktayız.
                            </p>
                        </div>
                    </div>
                    <div className='card shadow border-0'>
                        <img src="./images/network.jpg" width="75%" className='rounded' />
                        <div className='card-body p-4'>
                            <h5>Network</h5>
                            <p className='mb-0 text-truncate-3'>
                                Altyapı ve kablolama çözümleri, network cihazlarının seçimi ve VPN hizmetlerini FyberTech Yazılım ile karşılayabilirsiniz.
                            </p>
                        </div>
                    </div>
                    <div className='card shadow border-0'>
                        <img src="./images/hosting.jpg" width="75%" className='rounded' />
                        <div className='card-body p-4'>
                            <h5>Web Hosting</h5>
                            <p className='mb-0 text-truncate-3'>
                                Web tasarım hizmetlerini, web domain ve hosting hizmetlerini siz müşterilerimize kusursuz bir şekilde sağlamaktayız.
                            </p>
                        </div>
                    </div>
                    <div className='card shadow border-0'>
                        <img src="./images/development.jpg" width="75%" className='rounded' />
                        <div className='card-body p-4'>
                            <h5>Yazılım Geliştirme</h5>
                            <p className='mb-0 text-truncate-3'>
                                Sizler için yaratıcı çözümleri ve kolay kullanımıyla, ürettiğimiz web uygulamalarımızı iş takibi ve gelir arttırmak amacı ile kullanabilirsiniz.
                            </p>
                        </div>
                    </div>
                    <div className='card shadow border-0'>
                        <img src="./images/project-management.jpg" width="75%" className='rounded' />
                        <div className='card-body p-4'>
                            <h5>Proje Yönetimi</h5>
                            <p className='mb-0 text-truncate-3'>
                                FyberTech Yazılım olarak, kurum veya işletmenin sürdürdüğü işlerin daha hızlı, etkin ve verimli hale gelmesi için bilgi teknolojileri kullanılarak hazırlanan proje yönetimi konusunda size destek vermekteyiz.
                            </p>
                        </div>
                    </div>
                </Slider>
            </div>
        </Element>
    )
}

export default Services