import React, { useEffect, useState } from 'react'
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

const ScrollTop = () => {
    const [active, setActive] = useState(false);

    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        if (scrollPosition > 500) {
            setActive(true);
        }
        else {
            setActive(false)
        }
    };

    useEffect(() => {
        handleScroll();
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <Link to="pageTop" spy={true} smooth={true} duration={0}>
            <div className={`scroll-to-top ${active ? "show" : ""}`}>
                <i className="fa-solid fa-chevron-up text-white fa-xl"></i>
            </div>
        </Link>
    )
}

export default ScrollTop